/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo,
  stepWithFallback,
  setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit,
  whenSplitInRange,
  getCreativesInRandomOrder,
  skipOnMultifaceError,
  getCreativesInRandomOrderWithWeights
} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";

function getGroups() {
  return [
    groups.g1,
  ];
}

const t3557 = {
  id: 3557,
  images: [
    {src: "#"},
    {src: "https://tooncoin-designers.test.photo-cdn.net/assets/templates/for3557.jpg"}
  ],
};

function getCreatives(fileIndex) {
  return setCreativesGroup(groups.g1, [
    combo([{id: 7083, images: [{src: "@" + fileIndex}]}, 8609]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7083, 8609]),

    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 2345, 8612]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 2345, 8612]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 2345, 8634]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 2345, 8634]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 2345, 8635]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 2345, 8635]),

    combo([{id: 7089, images: [{src: "@" + fileIndex}]}, 8605]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7089, 8605]),
    combo([{id: 7089, images: [{src: "@" + fileIndex}]}, 8605]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7089, 8605]),
    combo([{id: 7089, images: [{src: "@" + fileIndex}]}, 8618]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7089, 8618]),
    combo([{id: 7089, images: [{src: "@" + fileIndex}]}, 8630]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7089, 8630]),
    combo([{id: 7089, images: [{src: "@" + fileIndex}]}, 8624]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7089, 8624]),
    combo([{id: 7089, images: [{src: "@" + fileIndex}]}, 8620]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7089, 8620]),

    combo([{id: 6683, images: [{src: "@" + fileIndex}]}, 8606]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 6683, 8606]),
    combo([{id: 6683, images: [{src: "@" + fileIndex}]}, 8623]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 6683, 8623]),
    combo([{id: 7116, images: [{src: "@" + fileIndex}]}, 8623]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7116, 8623]),
    combo([{id: 6683, images: [{src: "@" + fileIndex}]}, 8611]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 6683, 8611]),
    combo([{id: 6683, images: [{src: "@" + fileIndex}]}, 8613]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 6683, 8613]),

    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 8633]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 6455, 8637]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 8616]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 6455, 8638]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 8619]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 6455, 8639]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 8614]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 6455, 8640]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 8610]),
    combo([{id: 7521, images: [{src: "@" + fileIndex}]}, 6455, 8641]),

    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8631]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8631]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8607]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8607]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8615]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8615]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8622]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8622]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8621]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8621]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8608]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8608]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8632]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8632]),

    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8625]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8625]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8626]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8626]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8627]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8627]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, t3557, 3746, 8617]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, t3557, 3746, 8617]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8628]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8628]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 3152, 2763, 6821, 8629]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 3152, 2763, 6821, 8629]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8606]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8606]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8636]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8636]),
    combo([{id: 7088, images: [{src: "@" + fileIndex}]}, 8642]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 7088, 8642]),
    combo([{id: 6683, images: [{src: "@" + fileIndex}]}, 8642]),
    combo([{id: 7333, images: [{src: "@" + fileIndex}]}, 6683, 8642]),
  ]);
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function(fileIndex) {
    return getCreatives(fileIndex);
  },
};
